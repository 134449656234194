import React from "react";
import SimpleAutocomplete from "../../Components/SimpleAutocomplete";

const PercentageTable = ({ data, options, value, onChange, label }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <h6>Results</h6>
      <div style={{ width: "25%", marginLeft: "auto" }}>
        <SimpleAutocomplete
          id="poll_results_select"
          name="poll_results_select"
          label={label}
          options={options}
          value={value}
          onChange={onChange}
        />
      </div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ backgroundColor: "#d6f0f7" }}>
          <tr>
            <th style={{ padding: "10px", textAlign: "left" }}>Option</th>
            <th style={{ padding: "10px", textAlign: "left" }}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} style={{ backgroundColor: "#FAFAFA" }}>
              <td style={{ padding: "10px" }}>{item.name}</td>
              <td style={{ padding: "10px" }}>{item.percentage}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PercentageTable;
